<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>考务管理</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{
                    path: '/examination_Management/individual_examination_management/examination_home_manage/examination_home_manage',
                }"
                >个人赛考务工具</el-breadcrumb-item
            >
            <el-breadcrumb-item>表单答题解锁码管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">信息筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="100px"
                ref="queryFormRef"
            >
                <el-row :gutter="10">
                    <el-col :span="6">
                        <el-form-item label="赛区" prop="districtId">
                            <el-select
                                placeholder="请选择赛区"
                                style="width: 100%"
                                v-model="queryForm.districtId"
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="参赛码" prop="matchCode">
                            <el-input
                                v-model="queryForm.matchCode"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="search"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" style="width: 100%" @click="reset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 15px">
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="9">
                    <span class="title_class">解锁码</span>
                </el-col>
                <el-col :span="15" style="text-align: right">
                    <el-button type="primary" @click="showEditInfoFormClicked"
                        >新增解锁码</el-button
                    >
                    <el-button
                        type="primary"
                        plain
                        @click="downloadCodeData"
                        icon="el-icon-download"
                        >批量导出</el-button
                    >
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column label="参赛码" prop="code">
                    <template slot-scope="scope">
                        <el-link
                            type="primary"
                            @click="handleToDetail(scope.row.code)"
                            >{{ scope.row.code }}</el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    label="赛区"
                    prop="districtName"
                ></el-table-column>
                <el-table-column
                    label="赛程"
                    prop="raceSchedule"
                    :formatter="matchTypeFormatter"
                ></el-table-column>
                <el-table-column
                    label="试卷"
                    prop="questionPaperId"
                    :formatter="questionPaperIdFormatter"
                ></el-table-column>
                <el-table-column
                    label="组别"
                    prop="groupType"
                    :formatter="groupFormatter"
                ></el-table-column>
                <el-table-column
                    label="开始时间"
                    prop="startTime"
                ></el-table-column>
                <el-table-column
                    label="结束时间"
                    prop="endTime"
                ></el-table-column>
                <el-table-column
                    label="创建时间"
                    prop="updateTime"
                ></el-table-column>
                <el-table-column label="操作" width="200px" fixed="right">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="small"
                            style="color: #f56c6c"
                            @click="deleteDialog(scope.row)"
                            >删除</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            icon="el-icon-edit"
                            @click="renewalEditClicked(scope.row)"
                            >修改</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            icon="el-icon-s-grid"
                            @click="generateCodeClicked(scope.row)"
                            >二维码</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 编辑信息的dialog -->
        <el-dialog
            title="编辑兑换码信息"
            :visible.sync="showEditInfoForm"
            @close="editInfoDialogClose"
            width="50%"
        >
            <el-form
                :model="editInfoForm"
                label-width="140px"
                ref="editInfoFormRef"
                :rules="editInfoRules"
                style="width: 100%"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="赛区" prop="districtId">
                            <el-select
                                placeholder="请选择赛区"
                                style="width: 100%"
                                v-model="editInfoForm.districtId"
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="赛程" prop="raceSchedule">
                            <el-select
                                v-model="editInfoForm.raceSchedule"
                                placeholder="请选择"
                                style="width: 100%"
                                disabled
                            >
                                <el-option
                                    v-for="item in dict_matchType"
                                    :key="item.id"
                                    :label="item.scheduleName"
                                    :value="item.id"
                                ></el-option>
                            </el-select> </el-form-item
                    ></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="参赛码" prop="code">
                            <el-input
                                v-model="editInfoForm.code"
                                placeholder="不填随机生成"
                                clearable
                                style="width: 100%"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="组别" prop="groupType">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                @change="groupSelect"
                                clearable
                                v-model="editInfoForm.groupType"
                            >
                                <el-option
                                    v-for="item in dict_group"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开始时间" prop="startTime">
                            <el-date-picker
                                type="datetime"
                                placeholder="选择日期/时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%"
                                v-model="editInfoForm.startTime"
                                @change="addBeginTimeChangeStart"
                                clearable
                                :picker-options="addTimepickerOptionsStart"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="结束时间" prop="endTime">
                            <el-date-picker
                                type="datetime"
                                placeholder="选择日期/时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%"
                                clearable
                                v-model="editInfoForm.endTime"
                                @change="addEndTimeChangeEnd"
                                :picker-options="addTimepickerOptionsEnd"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="试卷" prop="questionPaperId">
                            <el-select
                                @change="$forceUpdate()"
                                placeholder="请选择"
                                style="width: 100%"
                                clearable
                                v-model="editInfoForm.questionPaperId"
                                :disabled="editInfoForm.groupType === null"
                            >
                                <el-option
                                    v-for="item in examListData"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showEditInfoForm = false">取 消</el-button>
                <el-button type="primary" @click="saveInfo">保 存</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="参赛二维码"
            :visible.sync="codeDialogVisible"
            width="40%"
        >
            <el-image :src="codeSrc">
                <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                </div>
            </el-image>
            <span slot="footer" style="text-align: center">
                <el-button type="primary" @click="downLoadImageCode"
                    >下 载</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { queryDistrictPage, getSemiGamecode, saveSemiGamecode, updateSemiGamecode, formMatchCodeDelete, getQuestionPaper, createFormQrCodeImage } from '@/http/api'

export default {
  data () {
    return {
      codeSrc: '',
      codeDialogVisible: false,
      setType: null,
      showEditInfoForm: false,
      dataList: [],
      examListData: [],
      dict_district: [],
      total: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      editInfoForm: {
        districtId: null,
        code: '',
        endTime: '',
        raceSchedule: '2',
        groupType: null,
        startTime: null,
        questionPaperId: null
      },
      addTimepickerOptionsStart: {},
      addTimepickerOptionsEnd: {},
      dict_matchType: this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.dict_matchType = value }),
      dict_group: this.$userInfo.dict_group(),
      editInfoRules: {
        districtId: { required: true, message: '请选择', trigger: 'change' },
        questionPaperId: [{ required: true, message: '请选择', trigger: 'change' }],
        endTime: [{ required: true, message: '请选择', trigger: 'change' }],
        startTime: [{ required: true, message: '请选择', trigger: 'change' }],
        raceSchedule: [{ required: true, message: '请选择', trigger: 'change' }],
        groupType: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  created () {
    this.getExamListData()
    this.getDistrictList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    editInfoDialogClose () {
      console.log('q12312312')
      this.$refs.editInfoFormRef.resetFields()
      this.editInfoForm = {
        districtId: null,
        code: '',
        endTime: '',
        raceSchedule: '2',
        groupType: null,
        startTime: null,
        questionPaperId: null
      }
      this.getExamListData()
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    getDataList () {
      getSemiGamecode(this.queryForm).then(res => {
        this.dataList = res.data.list
        this.total = res.data.total
      })
    },
    search () {
      this.pageIndex = 1
      this.getDataList()
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.search()
    },
    saveInfo () {
      if (this.setType === 'change') {
        updateSemiGamecode(this.editInfoForm).then((res) => {
          this.showEditInfoForm = false
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      } else {
        saveSemiGamecode(this.editInfoForm).then((res) => {
          this.showEditInfoForm = false
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      }
    },
    // 新增表单答题兑换码
    showEditInfoFormClicked () {
      this.setType = 'add'
      this.showEditInfoForm = true
    },
    groupSelect () {
      this.editInfoForm.questionPaperId = null
      this.getExamListData()
    },
    getExamListData () {
      var querFormData = {
        level: this.editInfoForm.groupType,
        type: 'form',
        status: true,
        pageNum: 1,
        pageSize: 1000
      }
      getQuestionPaper(querFormData).then((res) => {
        this.examListData = res.data.list
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 修改解锁码信息
    renewalEditClicked (rowData) {
      console.log('rowData', rowData)
      this.setType = 'change'
      this.editInfoForm = rowData
      this.editInfoForm.raceSchedule = rowData.raceSchedule + ''
      this.showEditInfoForm = true
    },
    // 修改开始时间的判断
    addBeginTimeChangeStart () {
      console.log('this.editInfoForm.', this.editInfoForm)
      if (this.editInfoForm.endTime) {
        if (this.editInfoForm.endTime <= this.editInfoForm.startTime) {
          this.$message.warning('开始时间必须小于结束时间！')
          this.editInfoForm.startTime = ''
        }
      }
      this.addTimepickerOptionsEnd = Object.assign({}, this.addTimepickerOptionsEnd, {
        disabledDate: (time) => {
          if (this.editInfoForm.startTime) {
            return new Date(time).getTime() < new Date(this.editInfoForm.startTime).getTime()
          }
        }
      })
    },
    // 修改结束时间的判断
    addEndTimeChangeEnd () {
      if (this.editInfoForm.startTime) {
        if (this.editInfoForm.endTime <= this.editInfoForm.startTime) {
          this.$message.warning('结束必须大于开始时间！')
          this.editInfoForm.endTime = ''
        }
      }
      this.addTimepickerOptionsStart = Object.assign({}, this.addTimepickerOptionsStart, {
        disabledDate: (time) => {
          if (this.editInfoForm.endTime) {
            return new Date(time).getTime() > new Date(this.editInfoForm.endTime).getTime()
          }
        }
      })
    },
    // 删除兑换码
    deleteDialog (rowData) {
      this.$confirm('是否确认删除该解锁码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        formMatchCodeDelete({ id: rowData.id }).then((res) => {
          this.$message.success('删除成功')
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      }).catch(() => { })
    },
    downloadCodeData () {
      var downLoadUrl = this.$env.baseIP + 'formMatchCode/export'
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, this.queryForm)
    },
    // 生成二维码点击事件
    generateCodeClicked (rowData) {
      createFormQrCodeImage({ id: rowData.id }).then((res) => {
        this.codeSrc = res.data
        this.codeDialogVisible = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 下载二维码
    downLoadImageCode () {
      this.codeDialogVisible = false
      const image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = this.codeSrc
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.download = 'codeImage'
          a.href = url
          a.click()
          a.remove()
          URL.revokeObjectURL(url)
        })
      }
      // const a = document.createElement('a') // 创建一个a元素
      // a.href = this.codeSrc // a元素图片地址
      // a.download = 'codePic' // 图片名
      // a.click()
    },
    // 比赛类型转文字
    matchTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_matchType) {
        if (Object.hasOwnProperty.call(this.dict_matchType, key)) {
          const element = this.dict_matchType[key]
          if (element.id === data.raceSchedule + '') {
            temp = element.scheduleName
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_group) {
        if (Object.hasOwnProperty.call(this.dict_group, key)) {
          const element = this.dict_group[key]
          if (element.dictValue === data.groupType) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    districtFormatter (data) {
      var temp = '-'
      for (const key in this.dict_district) {
        if (Object.hasOwnProperty.call(this.dict_district, key)) {
          const element = this.dict_district[key]
          if (element.id === data.districtId + '') {
            temp = element.districtName
          }
        }
      }
      return temp
    },
    questionPaperIdFormatter (data) {
      var temp = '-'
      for (const key in this.examListData) {
        if (Object.hasOwnProperty.call(this.examListData, key)) {
          const element = this.examListData[key]
          if (element.id === data.questionPaperId) {
            temp = element.name
          }
        }
      }
      return temp
    },
    handleToDetail (code) {
      this.$router.push({
        path: '/schedule_competitions/schedule_formanswer_competition',
        query: {
          code
        }
      })
    }
  }
}
</script>

<style>
</style>
